import { useDispatch, useSelector } from 'react-redux';
import styles from './ExploreApp.module.sass';
import { useEffect, useRef, useState } from 'react';
import SidePanel from 'src/hci/organism/SidePanel/SidePanel';
import { headerBreadcrumb } from 'src/store/UiSlice';
import { setContext } from 'src/redux/ducks/appContextSlice';
import OperationDashboard from './OperationDashboard/OperationDashboard';
import EntityList from './EntityList/EntityList';
import DeviceOnboarding from '../DeviceApp/OnboardDeviceApp/DeviceOnboarding/DeviceOnboarding';
import AddUnit from '../SecurityApp/AddUnit/AddUnit';

function ExploreApp() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(headerBreadcrumb([
      {title: "Dashboard", link: "/dashboard"},
      {title: "Explore App", link: "/dashboard/explore-app"}
    ]))

    dispatch(
      setContext({
        theme: { color: "#2699fb", name: "explore", highlight: "#00b93811" },
      })
    );
  }, []);

  return (
    <div className={styles.exploreApp}>
      <OperationDashboard />
    </div>
  )
}

export default ExploreApp