import { useEffect, useMemo } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { DeviceMapMarkerTypes, DeviceMapPathType } from "src/components/DeviceMap/DeviceMap";
import { addToPathCoordinates, setMarkers } from "src/components/DeviceMap/DeviceMapSlice";
import { deviceTypes } from "src/helper/constants";
import { DeviceOnlineStatus } from "src/helper/useDockList";
import { setDeviceInfo } from "../device/DeviceServiceSlice";
import { PersonMarkerTypes } from "src/components/DeviceMap/MapPersonMarker/MapPersonMarker";
import { setDeviceDrcState } from "../flight/FlightServiceSlice";

export const useCollectorService = ({ deviceService, missionService, userService }) => {
  const dispatch = useDispatch();
  const deviceTelemetries = useSelector((state) => state.deviceService.deviceTelemetries);
  const store = useStore();

  // Create device map markers
  const deviceMapMarkers = useMemo(() => {
    const docks = deviceService.devices.filter((device) => device.type === deviceTypes.DOCK && device.coordinates);
    const drones = deviceService.devices.filter((device) => 
      device.type === deviceTypes.DRONE && 
      device.onlineStatus === DeviceOnlineStatus.ONLINE && 
      deviceTelemetries[device.id]?.gps
    );

    const droneMarkers = drones.map((drone) => {
      const deviceTelemetry = deviceTelemetries[drone.id];
      const heading = deviceTelemetry?.facing?.heading || 0;

      return ({
        type: DeviceMapMarkerTypes.DRONE,
          title: drone.name,
          lat: deviceTelemetry.gps?.lat,
          long: deviceTelemetry.gps?.long,
          data: {
            sourceId: drone.id,
            raw: drone,
            heading: heading,
          }
      });
    });

    const dockMarkers = docks.flatMap((dock) => {
      const deviceTelemetry = deviceTelemetries[dock.id];
      const subDeviceTelemetries = deviceTelemetries[deviceTelemetry?.sub_device?.device_sn];
      const subDeviceInfo = deviceService.devices.find(device => device.serialNumber === deviceTelemetry?.sub_device?.device_sn);
      const isSubDeviceOutOfDock = deviceTelemetry?.drone_in_dock === 0 || subDeviceTelemetries?.mode_code !== 0;
      const isSubDeviceOnline = deviceTelemetry?.sub_device?.device_online_status;

      const dockMarker = ({
        type: DeviceMapMarkerTypes.DOCK,
        title: dock.name,
        lat: deviceTelemetry?.latitude,
        long: deviceTelemetry?.longitude,
        data: {
          sourceId: dock.id,
          ...(!!deviceTelemetry?.sub_device?.device_online_status ? {subSourceId: deviceTelemetry?.sub_device?.device_sn} : {}),
          isOnline: dock.onlineStatus === DeviceOnlineStatus.ONLINE,
          raw: dock,
        }
      });

      const subDeviceMarker = subDeviceTelemetries && isSubDeviceOnline ? ({
        type: DeviceMapMarkerTypes.DRONE,
        title: dock.name + ' (Drone)',
        lat: subDeviceTelemetries.latitude,
        long: subDeviceTelemetries.longitude,
        data: {
          sourceId: deviceTelemetry?.sub_device?.device_sn,
          parentSourceId: dock.id,
          heading: subDeviceTelemetries?.attitude_head,
          isOnline: isSubDeviceOnline,
          raw: {
            ...deviceTelemetry?.sub_device,
            ...subDeviceInfo
          },
        }
      }) : null;

      return [dockMarker, subDeviceMarker].filter(Boolean);
    });

    const unitMarkers = missionService.unitList?.filter(item => item.location?.lat).map((unit) => ({
      type: DeviceMapMarkerTypes.OPERATION_UNIT,
      title: unit.name,
      lat: unit.location?.lat,
      long: unit.location?.long,
      data: {
        sourceId: unit.id,
        raw: unit,
      }
    }));

    const mobileMarkers = userService.mobileClients?.filter(item => item.location?.lat).map((mobile) => ({
      type: DeviceMapMarkerTypes.MOBILE_DEVICE,
      title: mobile.user?.displayName,
      lat: mobile.location?.lat,
      long: mobile.location?.long,
      data: {
        sourceId: mobile.id,
        raw: mobile,
        type: mobile.user?.role === 'rc' ? PersonMarkerTypes.RC.id : PersonMarkerTypes.VIEWER.id,
      }
    }));

    const markers = [...unitMarkers, ...dockMarkers, ...droneMarkers, ...mobileMarkers].filter((marker) => {
      return marker.lat > -90 && marker.lat < 90 && marker.long > -180 && marker.long < 180;
    });

    return markers;
  }, [deviceService.devices, missionService.unitList, deviceTelemetries]);

  // Update drone info with coordinates data
  useEffect(() => {
    const drones = deviceService.devices.filter((device) => 
      device.type === deviceTypes.DRONE && 
      device.onlineStatus === DeviceOnlineStatus.ONLINE && 
      deviceTelemetries[device.id]?.gps
    );

    drones.forEach(device => {
      if(deviceTelemetries[device.id]?.gps){
        dispatch(setDeviceInfo({ deviceId: device.id, data: { coordinates: deviceTelemetries[device.id].gps } }));
        dispatch(addToPathCoordinates({ 
          deviceId: device.id, 
          coords: [[deviceTelemetries[device.id].gps.long, deviceTelemetries[device.id].gps.lat]],
          type: DeviceMapPathType.DEVICE_FOOTMARK
        }))
      }
    });
    
  }, [deviceService.devices, deviceTelemetries]);

  // Update dock location with coordinates data
  useEffect(() => {
    const docks = deviceService.devices.filter((device) => 
      device.type === deviceTypes.DOCK && 
      device.onlineStatus === DeviceOnlineStatus.ONLINE && 
      deviceTelemetries[device.id]?.latitude &&
      deviceTelemetries[device.id]?.longitude
    );

    docks.forEach(device => {
      dispatch(setDeviceInfo({ deviceId: device.id, data: { coordinates: {
        lat: deviceTelemetries[device.id].latitude,
        long: deviceTelemetries[device.id].longitude
      }}}));
    });
  }, [deviceService.devices, deviceTelemetries]);

  useEffect(() => {
    const currMarkers = store.getState().deviceMap.markers;
    
    const keepMarkers = currMarkers?.filter(item => ![
      DeviceMapMarkerTypes.DRONE,
      DeviceMapMarkerTypes.OPERATION_UNIT,
      DeviceMapMarkerTypes.DOCK,
      DeviceMapMarkerTypes.MOBILE_DEVICE,
    ].includes(item.type));

    dispatch(setMarkers([
      ...keepMarkers,
      ...deviceMapMarkers,
    ]));
  }, [deviceMapMarkers]);

  // Update DRC state for cloud devices
  useEffect(() => {
    const targetDevices = deviceService.devices.filter((device) => 
      device.type === deviceTypes.DOCK && 
      device.onlineStatus === DeviceOnlineStatus.ONLINE
    );

    const currDrcState = store.getState().flightService.drcState;

    targetDevices.forEach(device => {
      const newDeviceDrcState = deviceTelemetries[device.id]?.drc_state;

      if(newDeviceDrcState != undefined && currDrcState[device.id] !== newDeviceDrcState){
        dispatch(setDeviceDrcState({ deviceId: device.id, data: newDeviceDrcState }));
      }
    });
    
  }, [deviceService.devices, deviceTelemetries]);

  return true;
}