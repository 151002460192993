import { useEffect, useRef } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { dockConnectionManager, frontendConnectionManager, onboardConnectionManager } from "src/helper/HubConnectionManager";
import { addLog } from "src/services/log/LogServiceSlice";
import { createLog } from "src/services/log/common/logUtils";
import { djiCloudCustomMethod, djiCloudMethod } from "../../common/constants";
import { removeMission, removeMissionPendingRequest, setCreateMissionProgress, setMissionInfo, setMissionPendingRequest } from "../MissionServiceSlice";
import useOnboardMissionObserver from "./useOnboardMissionObserver";
import useCloudMissionObserver from "./useCloudMissionObserver";
import { getMissionInfo, terminateRemoteCloudMission } from "./missionUtils";
import { MissionPendingRequestStatus, MissionPendingRequestType, MissionStatus, NotActiveMissionStatusSet, onboardWaypointAction } from "./missionConstants";
import { addOrUpdateMarkers, addOrUpdatePath, removeMarkers } from "src/components/DeviceMap/DeviceMapSlice";
import { DeviceMapLocationTypes, DeviceMapMarkerTypes, DeviceMapPathType } from "src/components/DeviceMap/DeviceMap";
import { getDeviceConnectionGroups, getDeviceInfo, getDeviceTelemetries } from "src/services/device/common/deviceUtils";
import useCurrentUserId from "src/helper/useCurrentUserId";
import { setDeviceInfo } from "src/services/device/DeviceServiceSlice";
import { DeviceOnlineStatus } from "src/helper/useDockList";
import useDialog from "src/helper/useDialog";
import { useTermianteMission } from "./useTerminateMission";
import { useBroadcastToFrontend } from "src/helper/useBroadcastToFrontend";
import { generateId } from "src/helper/utils";
import { useIssueMission } from "./useIssueMission";
import useEnvSettings from "src/helper/useEnvSettings";
import useCloudLiveFlight from "./useCloudLiveFlight";
import { deviceTypes } from "src/helper/constants";
import { DjiDockMode } from "src/services/device/common/deviceConstants";

export default function useMissionObserver() {
  const onboardObserver = useOnboardMissionObserver();
  const cloudObserver = useCloudMissionObserver();
  const currUserId = useCurrentUserId();
  const dialog = useDialog();
  const envSettings = useEnvSettings();
  const { sendIssueMission, issueResult } = useIssueMission();
  const terminateMission = useTermianteMission();
  const broadcastToFrontend = useBroadcastToFrontend();
  const dockMessageHandlerIds = useRef([]);
  const tempDockMessageHandlerIds = useRef([]);
  const frontGroupMessageHandlerIds = useRef([]);
  const onboardGroupMessageHandlerIds = useRef([]);
  const tempOnboardGroupMessageHandlerIds = useRef([]);
  const pendingRequestsIntervalId = useRef(null);
  const liveFlight = useCloudLiveFlight();
  const store = useStore();
  const dispatch = useDispatch();
  const deviceList = useSelector((state) => state.deviceService.devices);
  const missions = useSelector((state) => state.missionService.missions);
  const createMissionResult = useSelector((state) => state.missionService.createMissionResult);

  // remove missions when a device goes offline
  useEffect(() => {
    const missions = store.getState().missionService.missions;

    deviceList.forEach(device => {
      if(device.onlineStatus !== DeviceOnlineStatus.ONLINE) {
        const mission = missions.find(item => item.deviceId === device.id && !NotActiveMissionStatusSet.includes(item.status));

        if(mission) {
          dispatch(removeMission({ id: mission.id }));
        }
      }
    });

    missions?.filter?.(item => item.deviceId && !deviceList.find(device => device.id === item.deviceId))?.forEach(mission => {
      dispatch(removeMission({ id: mission.id }));
    });
  }, [deviceList]);

  // operations upon mission removal or completion
  useEffect(() => {
    missions.forEach(mission => {
      if(NotActiveMissionStatusSet.includes(mission.status)) {
        dispatch(removeMarkers({
          type: DeviceMapMarkerTypes.LOCATION,
          data: { 
            id: mission.id,
            locationType: DeviceMapLocationTypes.MISSION_DESTINATION
          }
        }));
      } else if ([MissionStatus.PREPARING, MissionStatus.EXECUTING, MissionStatus.PAUSED].includes(mission.status)) {
        const lastWaypoint = mission.waypoints?.[mission.waypoints.length - 1];

        if(lastWaypoint) {
          dispatch(addOrUpdateMarkers({
            type: DeviceMapMarkerTypes.LOCATION,
            title: 'Destination',
            lat: parseFloat(lastWaypoint.latitude).toFixed(7),
            long: parseFloat(lastWaypoint.longitude).toFixed(7),
            data: {
              id: mission.id,
              locationType: DeviceMapLocationTypes.MISSION_DESTINATION
            }
          }));
        }
      }
    });

    const deviceMapMarkers = store.getState().deviceMap.markers;
    deviceMapMarkers?.forEach(marker => {
      if(marker.data?.locationType === DeviceMapLocationTypes.MISSION_DESTINATION && !missions.find(mission => mission.id === marker.data.id)) {
        dispatch(removeMarkers({
          type: DeviceMapMarkerTypes.LOCATION,
          data: marker.data
        }));
      }
    });
  }, [missions]);

  useEffect(() => {
    dockMessageHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: 'missionMessages/*',
        name: ['events', 'services_reply', 'osd', 'commands_reply'],
        handler: (message, meta) => {  
          const missions = store.getState().missionService.missions;
          
          if(message.method === djiCloudMethod.wayline.FLIGHTTASK_READY) {
            const flightIds = message.data?.flight_ids;
  
            Array.isArray(flightIds) && flightIds.forEach(flightId => {
              const mission = missions.find(item => item.flightId === flightId);
              
              if(mission) {
                const missionInfo = {
                  id: mission.id, 
                  isReady: true
                };
    
                dispatch(setMissionInfo(missionInfo));
              }
            });
          }
  
          if(message.method === djiCloudMethod.wayline.RETURN_HOME_INFO) {
            const { flightId, ...info } = message.data;
            const mission = missions.find(item => item.flightId === flightId);

            if(!mission)
              return;
  
            const missionInfo = {
              id: mission.id,
              returnHomeInfo: info,
            };
  
            dispatch(setMissionInfo(missionInfo));
          }

          if(message.method === djiCloudCustomMethod.mission.MISSION_QUERY) {
            const mission = missions.find(item => item.id === message.data?.id);
            const deviceInfo = getDeviceInfo(meta?.fromUserId?.split('_').shift());

            if(!mission && deviceInfo && message.data?.id) {
              const telemetries = getDeviceTelemetries(deviceInfo?.id);
              const mapCoords = message.data?.waypoints?.map(item => ([ item.longitude, item.latitude ]));
              
              const remoteMissionData = {
                ...message.data,
                deviceId: message.data?.dockId || message.data?.deviceId,
                status: String(message.data.status).toLowerCase()
              }
              
              if(telemetries?.mode_code === DjiDockMode.IN_OPERATION) {
                dispatch(setMissionInfo(remoteMissionData));

                if(mapCoords.length > 0)
                  dispatch(addOrUpdatePath({
                    deviceId: message.data.deviceId,
                    type: DeviceMapPathType.ACTIVE_MISSION_ROUTE,
                    coords: mapCoords
                  }));
              }
              else {
                console.log('❌ Mission query received but the device is not in operation mode. Trying to terminate remote mission...', message.data);
                terminateRemoteCloudMission(remoteMissionData.deviceId);
              }
            }

            if(deviceInfo) {
              dispatch(setDeviceInfo({
                deviceId: deviceInfo.id,
                data: {
                  initialized: Date.now(),
                }
              }));

              console.log('📡 Mission info received from dock', message.data)
            }
            else {
              console.log("❌ [dock] Received mission info doesn't match any mission/device", message.data);
            }
          }
        
          if([
            djiCloudMethod.wayline.FLIGHTTASK_READY,
            djiCloudMethod.wayline.RETURN_HOME_INFO,
          ].includes(message.method)) {
            const logItem = createLog('mission', message.method, message.data);
            dispatch(addLog(logItem))
          }
        },
      },
    ], 'mission-observer');

    frontGroupMessageHandlerIds.current = frontendConnectionManager.subscribeGroupMessages([
      {
        identity: 'frontendBroadcastMessages/*',
        name: ['broadcast'],
        handler: (message) => {
          console.log('📡 [frontendBroadcastMessages]', message);

          if(message.method === 'mission_create' || message.method === 'mission_update') {
            const currMissionInfo = getMissionInfo(item => item.id === message.data.id);
            const newMissionInfo = message.data;

            if(!currMissionInfo || !currMissionInfo.controllerId !== newMissionInfo.controllerId) {
              const mapCoords = newMissionInfo.waypoints?.map(item => ([ item.long || item.longitude, item.lat || item.latitude ]));

              dispatch(setMissionInfo(newMissionInfo));

              if(mapCoords.length > 0)
                dispatch(addOrUpdatePath({
                  deviceId: message.data.deviceId,
                  type: DeviceMapPathType.ACTIVE_MISSION_ROUTE,
                  coords: mapCoords
                }));
            }
          }

          if(message.method === 'mission_finish') {
            dispatch(removeMission({ id: message.data.missionId }));
          }
        },
      },
    ], 'mission-observer');

    onboardGroupMessageHandlerIds.current = onboardConnectionManager.subscribeGroupMessages([
      {
        identity: 'onboardWaypointInfoMessages/*',
        name: ['onDemandChannel'],
        handler: (message, meta) => {
          if(message.actionId === onboardWaypointAction.MISSION_QUERY) {
            const newMissionInfo = message?.mission_info;
            const deviceInfo = getDeviceInfo(meta?.fromUserId?.split('_').shift());
            const mission = getMissionInfo(item => item.id === newMissionInfo?.id);
            
            if(deviceInfo && !mission && newMissionInfo?.id) {
              if(newMissionInfo.status !== MissionStatus.COMPLETED) {
                const mapCoords = newMissionInfo.waypoints?.map(item => ([ item.long || item.longitude, item.lat || item.latitude ]));
                dispatch(setMissionInfo(newMissionInfo));

                if(mapCoords.length > 0)
                  dispatch(addOrUpdatePath({
                    deviceId: newMissionInfo?.deviceId,
                    type: DeviceMapPathType.ACTIVE_MISSION_ROUTE,
                    coords: mapCoords
                  }));
              }
            }
            
            if(deviceInfo) {
              dispatch(setDeviceInfo({
                deviceId: deviceInfo.id,
                data: {
                  initialized: Date.now(),
                }
              }));

              console.log('📡 Mission info received from drone', newMissionInfo);
            }
            else {
              console.log("❌ [onboard] Received mission info doesn't match any mission/device", newMissionInfo)
            }
          }
        },
      },
    ], 'mission-observer');

    pendingRequestsIntervalId.current = setInterval(() => {
      const missions = store.getState().missionService.missions;

      missions.forEach(mission => {
        const pendingRequests = mission.pendingRequests || [];
      
        pendingRequests.forEach(request => {
          let shouldTerminateMission = false;
          let shouldSetMissionToIdle = true;
          let showDialog = true;
          let message = 'Mission request timed out';
          let timeout = 15000;

          switch(request.requestType) {
            case MissionPendingRequestType.REGISTER_ON_SERVER:
              timeout = 30000;
              break;
            case MissionPendingRequestType.CLOUD_TAKEOFF_PREPARATION:
              timeout = 20000;
              break;
            case MissionPendingRequestType.CLOUD_TAKEOFF_TO_POINT:
              timeout = 120000;
              break;
            case MissionPendingRequestType.ONBOARD_ENTER_MISSION:
              timeout = 20000;
              showDialog = false;
              shouldSetMissionToIdle = false;
              break;
            case MissionPendingRequestType.ONBOARD_ENTER_OPERATION:
              timeout = 22000;
              showDialog = true;
              break;
            case MissionPendingRequestType.ONBOARD_RESUME_MISSION:
              timeout = 5000;
              showDialog = false;
              shouldSetMissionToIdle = true;
              message = 'Failed to resume the mission on the device, the waypoint will be canceled.';
              break;
          }

          const elapsed = new Date().getTime() - request.startTimestamp;

          timeout = request.timeout || timeout;
          message = request.failureMessage || message;
          
          shouldTerminateMission = mission?.controllerId === currUserId && shouldTerminateMission;
          message = `${message}. ${shouldTerminateMission ? 'The mission may be teminated now. ' : ''}(${request.requestType})`;

          if(elapsed > timeout) {
            const currMissionInfo = getMissionInfo(item => item.id === mission.id);
            console.log('❌ Mission request timeout', {mission, message, request, pendingRequests});

            if(showDialog && !dialog.isVisible()) {
              const deviceInfo = getDeviceInfo(currMissionInfo.deviceId);
              let title = 'Mission Error';

              if(deviceInfo?.name)
                title += ` - ${deviceInfo.name}`;

              dialog.fire({
                title,
                icon: 'error',
                text: message,
                showConfirmButton: true,
                showCloseButton: false,
                confirmButtonText: 'OK',              
              });
            }

            // if(shouldTerminateMission) {
            //   if(![MissionStatus.EXECUTING].includes(currMissionInfo?.status))
            //     terminateMission(mission.id);
            // }

            if(shouldSetMissionToIdle && currMissionInfo?.id) {
              if(![MissionStatus.EXECUTING].includes(currMissionInfo?.status)) {
                const missionChanges = {
                  id: mission.id,
                  status: MissionStatus.IDLE,
                  statusDescription: 'Mission request timed out.',
                };

                dispatch(setMissionInfo(missionChanges));
                broadcastToFrontend('mission_update', {
                  ...currMissionInfo,
                  ...missionChanges,
                });
              }
            }

            dispatch(removeMissionPendingRequest({
              missionId: mission.id,
              requestType: request.requestType,
            }));
          }
        });
      });
    }, 1000);
    
    return () => {
      dockConnectionManager.unsubscribeGroupMessages(dockMessageHandlerIds.current);
      frontendConnectionManager.unsubscribeGroupMessages(frontGroupMessageHandlerIds.current);
      clearInterval(pendingRequestsIntervalId.current);
    }
  }, []);

  useEffect(() => {
    if(createMissionResult === false) {
      dialog.fire({
        title: <b>Issue Mission</b>,
        text: "Failed to register the mission on the server, please try again later.",
        icon: 'error',
        showConfirmButton: true,
        confirmButtonText: 'OK',
      });

      dispatch(setCreateMissionProgress(null));
      return;
    } 
    else if(!createMissionResult) return;

    const deviceId = createMissionResult.deviceId;
    const connection = getDeviceConnectionGroups(deviceId);
    const missionDetails = getMissionInfo(item => item.id === createMissionResult.id);
    let missionData;

    if(createMissionResult.deviceType === deviceTypes.DRONE) {
      if(missionDetails) {
        missionData = {
          ...missionDetails, 
          status: MissionStatus.PREPARING,
          statusDescription: 'Going to issue the mission...',
        };
      }

      // tempOnboardGroupMessageHandlerIds.unsubscribeGroupMessages(onboardHandlerIds.current);
      // onboardHandlerIds.current = tempOnboardGroupMessageHandlerIds.subscribeGroupMessages([
      //   {
      //     identity: deviceId,
      //     name: [connection?.join?.onDemandChannel],
      //     handler: (data) => {
      //       console.log('🍭🍭🍭🍭🍭', data)
      //       if(
      //         data?.action === 'WaypointResult' &&
      //         data.actionId === onboardWaypointAction.MISSION_INIT
      //       ) {
      //         if(data.status) {
      //           console.log('✨ Mission data synced to drone', data);
      //           //sendIssueMission(createMissionResult.deviceId || createMissionResult.dockId, createMissionResult);
      //         }
      //         else {
      //           console.log('❌ Mission data sync failed', data);
      //         }
      //       }

      //       tempOnboardGroupMessageHandlerIds.unsubscribeGroupMessages(onboardHandlerIds.current);
      //     },
      //   },
      // ], 'mission-issue');

      onboardConnectionManager?.sendToGroup(deviceId, connection?.send?.commandChannel, {
        waypoint: {
          actionId: onboardWaypointAction.MISSION_INIT,
          ...missionData,
          waypoints: missionDetails?.waypoints?.[0] ? [missionDetails?.waypoints[0]] : [],
        }
      });

      broadcastToFrontend('mission_create', missionData, generateId());

      setTimeout(() => {
        // dispatch(setMissionPendingRequest({
        //   missionId: createMissionResult?.id,
        //   requestType: MissionPendingRequestType.ONBOARD_ENTER_MISSION,
        //   requestStatus: MissionPendingRequestStatus.PENDING
        // }));

        sendIssueMission(createMissionResult.deviceId || createMissionResult.dockId, createMissionResult);
      }, 2000);
    }
    
    if(createMissionResult.deviceType === deviceTypes.DOCK) {
      const deviceId = createMissionResult.deviceId;
      const waypoint = createMissionResult.waypoints?.slice(-1)[0];
      const telemetries = getDeviceTelemetries(deviceId);

      if(telemetries?.drone_in_dock && telemetries?.mode_code === 0) {
        const simulation = envSettings?.configs?.device?.dockSimulation === 'true';
        const deviceInfo = getDeviceInfo(deviceId);

        let title = 'Drone Takeoff';

        if(simulation)
          title += ' (Simulation)';

        if(deviceInfo?.name)
          title += ` - ${deviceInfo.name}`;

        dialog.fire({
          title: <b>{title}</b>,
          text: "Drone is inside the dock, do you want to start takeoff?",
          icon: 'question',
          showConfirmButton: true,
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonText: 'Takeoff',
          denyButtonText: 'Takeoff + Recording',
        }).then(result => {
          if (result.isConfirmed || result.isDenied) {
            liveFlight.sendFlightAuthorityGrab(deviceId ,data => {
              // if(data?.result !== 0) {
              //   console.log('❌ Flight authority grab failed', data);
              //   terminateRemoteCloudMission(missionData.deviceId);

              //   dialog.fire({
              //     title: <b>Drone Takeoff</b>,
              //     text: "Failed to grab flight authority, please try again later.",
              //     icon: 'error',
              //     showConfirmButton: true,
              //     confirmButtonText: 'OK',
              //   });

              //   return;
              // }

              console.log('☑️☑️ after get authority', data);
              
              if(missionDetails) {
                missionData = {
                  ...missionDetails, 
                  missionDetails: {
                    ...missionDetails?.missionDetails,
                    autoRecord: !!result.isDenied,
                  },
                  status: MissionStatus.PREPARING,
                  statusDescription: 'Takeoff request sent...',
                };
  
                dispatch(setMissionInfo(missionData));
                console.log('☑️☑️ after set mission details', missionData);
              }

              const additionalData = {};

              if(missionDetails?.missionDetails?.securityTakeoffHeight) {
                additionalData.security_takeoff_height = missionDetails?.missionDetails?.securityTakeoffHeight;
              }

              if(missionDetails?.missionDetails?.rthAltitude) {
                additionalData.rth_altitude = missionDetails?.missionDetails?.rthAltitude;
              }

              if(missionDetails?.missionDetails?.rcLostAction) {
                additionalData.rc_lost_action = missionDetails?.missionDetails?.rcLostAction;
              }
  
              initDockMission(missionData, () => {
                console.log('☑️☑️ after mission init, before takeoff', data);
                liveFlight.sendOneKeyTakeoff(deviceId ,{
                  max_speed: createMissionResult.speed,
                  target_height: createMissionResult.altitude,
                  target_latitude: waypoint?.latitude,
                  target_longitude: waypoint?.longitude,
                  distance: 10,
                  duration: 5,
                  flight_id: missionData?.flightId,
                  ...additionalData,
                });

                dispatch(setMissionPendingRequest({
                  missionId: missionDetails?.id,
                  requestType: MissionPendingRequestType.CLOUD_TAKEOFF_PREPARATION,
                  requestStatus: MissionPendingRequestStatus.PENDING
                }));
  
                broadcastToFrontend('mission_create', missionData, generateId());
              });
            });

            if(missionDetails) {
              missionData = {
                ...missionDetails, 
                status: MissionStatus.PREPARING,
                statusDescription: 'Aquring flight authority...',
              };

              dispatch(setMissionInfo(missionData));
            }
          }
          else {
            dispatch(removeMission({ id: createMissionResult.id }));
            terminateRemoteCloudMission(createMissionResult?.deviceId);
          }
        });
      }
      else if(false && telemetries?.mode_code !== 3) {
        // we don't start mission if drone is already in the air
        if(missionDetails) {
          missionData = {
            ...missionDetails, 
            status: MissionStatus.PREPARING,
            statusDescription: 'Mission request sent...',
          };

          setMissionInfo(missionData);
        }

        initDockMission(missionData, () => {
          liveFlight.sendFlyToPoint(deviceId, {
            max_speed: createMissionResult.speed,
            distance: 10,
            duration: 5,
            points: [{
              latitude: waypoint?.latitude,
              longitude: waypoint?.longitude,
              height: createMissionResult.altitude,
            }],
          });

          broadcastToFrontend('mission_create', missionData, generateId());
        });
      } 
      else {
        let title = 'Drone Takeoff';
        const deviceInfo = getDeviceInfo(deviceId);

        if(deviceInfo?.name)
          title += ` - ${deviceInfo.name}`;

        dialog.fire({
          title: <b>{title}</b>,
          text: "The dock is not ready to start takeoff at the moment or malfunctioned. we'll terminate the mission. please refresh the page in case of any issues.",
          icon: 'error',
          showConfirmButton: true,
          confirmButtonText: 'OK',
        });

        if(missionData) {
          terminateRemoteCloudMission(missionData.deviceId);
          dispatch(removeMission({ id: missionData.id }));
        }
      }
    }
  }, [createMissionResult]);

  const initDockMission = (missionData, onSuccess) => {
    const { deviceId } = missionData;
    const connection = getDeviceConnectionGroups(deviceId);
    const deviceInfo = getDeviceInfo(deviceId);
    const  {kmz, ...simpleFlightDetails } = missionData.flightDetails;
    missionData.flightDetails = simpleFlightDetails;

    console.log('☑️☑️ inside initDockMission', missionData);

    const handlersStorageId = 'mission_controller_dockHandlerIds_' + deviceId;
    const handlerIds = localStorage.getItem(handlersStorageId) ? JSON.parse(localStorage.getItem(handlersStorageId)) : [];
    localStorage.removeItem(handlersStorageId);

    dockConnectionManager.unsubscribeGroupMessages([...handlerIds, ...tempDockMessageHandlerIds.current]);
    tempDockMessageHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: deviceId,
        name: [connection?.join?.commands_reply],
        handler: (message) => {
          console.log('☑️☑️ mission_init result handler', missionData);
          if(message?.method !== djiCloudCustomMethod.mission.MISSION_INIT) return;
          // if(message?.tid !== tid) return;

          // if(message?.result !== 0) {
          //   console.log('❌ Mission data sync failed', message);
          //   return;
          // }

          console.log('✨ Mission data synced to dock', message);
          typeof onSuccess === 'function' && onSuccess();
          dockConnectionManager.unsubscribeGroupMessages(tempDockMessageHandlerIds.current);
        },
      }
    ], 'mission-controller');

    const { tid } = dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.send?.commands, {
      method: djiCloudCustomMethod.mission.MISSION_INIT,
      data: missionData,
      gateway: deviceInfo?.serialNumber,
    });
  }

  return {};
}
