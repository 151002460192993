import classNames from 'classnames';
import './MapLocationMarker.sass';
import { MdMapsHomeWork } from 'react-icons/md';
import DestinationPointIcon from 'src/assets/img/destination_point.svg?react';

function MapLocationMarker({title, className, icon: Icon, ...props}) {
  return (
    <div className={classNames("map-location-marker", className)} title="Location" {...props}>
      <div className="main">
        <DestinationPointIcon alt="Location" className="base" />
        { Icon ? <Icon className="icon" /> : <MdMapsHomeWork className="icon" /> }
      </div>
      <div className="title">
        {title}
      </div>
    </div>
  )
}

export default MapLocationMarker