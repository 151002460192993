import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import usePlainVideoStream from "./usePlainVideoStream"
import { dockConnectionManager } from "./HubConnectionManager";
import useDeviceConnection from "src/services/device/common/useDeviceConnection";
import { djiCloudCustomMethod } from "src/services/common/constants";
import useDeviceInfo from "src/services/device/common/useDeviceInfo";
import { DeviceOnlineStatus } from "./useDockList";
import useSubDevice from "src/services/device/common/useSubDevice";
import { deviceConnectionStatus } from "src/services/device/useDeviceService";
import { getDeviceInfo } from "src/services/device/common/deviceUtils";
import useCurrentUserId from "./useCurrentUserId";
import { setDockConnectionInfo } from "src/services/device/DeviceServiceSlice";
import { useDispatch, useSelector, useStore } from "react-redux";

export const useDockVideoStream = (dockId = null, subDevice = false) => {
  const groupHandlerIds = useRef([]);
  const lastGetInfo = useRef(null);
  const noResponseTimeoutId = useRef(null);
  const initialQueryTimeoutId = useRef(null);
  const invalidResultTimeoutId = useRef(null);
  const streamingConfigRef = useRef();
  const dockConnection = useDeviceConnection(dockId);
  const dockInfo = useDeviceInfo(dockId)
  const [streamingConfig, setStreamingConfig] = useState();
  const subDeviceInfo = useSubDevice(dockId);
  const serialNumber = useMemo(() => subDevice ? subDeviceInfo?.device_sn : dockInfo?.serialNumber, [subDeviceInfo, dockInfo, subDevice]);
  const cameraIndex = subDevice ? '80-0-0' : '165-0-7';
  const videoId = `${serialNumber}/${cameraIndex}/normal-0`;
  const streamStatus = useSelector((state) => state.deviceService.devices?.find(device => device.id === dockId)?.streamStatus?.[subDevice ? 'subDevice' : 'dock']);
  const currUserId = useCurrentUserId();
  const store = useStore();
  const dispatch = useDispatch();

  const getLiveStream = useCallback(() => {
    if(lastGetInfo.current) return;

    const getMethod = subDevice ? 'get_drone_video_v2' : 'get_dock_video_v2';
    streamingConfigRef.current = null;
    setStreamingConfig(null);
    
    const { tid } = dockConnectionManager.sendToDjiCloudGroup(dockId, dockConnection?.group?.send?.commands, {
      method: getMethod,
      data: {
        serialNumber: serialNumber,
      },
      gateway: dockInfo?.serialNumber,
      userId: currUserId,
    });
    
    console.log('📸⬇ Get stream sent', serialNumber);

    lastGetInfo.current = {
      serialNumber,
      getMethod,
      subDevice,
      tid,
      timestamp: Date.now(),
    };
    
    if(!noResponseTimeoutId.current) {
      noResponseTimeoutId.current = setTimeout(() => {
        if(lastGetInfo.current?.tid === tid){
          lastGetInfo.current = null;
          noResponseTimeoutId.current = null;

          console.log('📸⬇ No response received, retrying after 30 seonds...', serialNumber);
          getLiveStream();
        }
      }, 30000);
    }
  }, [dockConnection, dockInfo.serialNumber, serialNumber, currUserId]);

  const queryLiveStream = useCallback(() => {
    const currDeviceInfo = getDeviceInfo(serialNumber);
    clearTimeout(initialQueryTimeoutId.current);

    if(currDeviceInfo?.onlineStatus !== DeviceOnlineStatus.ONLINE) 
      return;

    const currStreamConfig = store?.getState()?.deviceService?.dockConnections?.find((dock) => dock.deviceId === dockId)?.[subDevice ? 'subStreamConfig' : 'dockStreamConfig'];

    if(currStreamConfig) {
      console.log('📺💡 Livestream config exists', serialNumber, currStreamConfig);

      setStreamingConfig({
        ...currStreamConfig,
        timestamp: Date.now(),
      });

      return;
    }

    if(lastGetInfo.current?.serialNumber === serialNumber && lastGetInfo.current?.subDevice === subDevice)
      return;

    if(
      (!subDevice && currDeviceInfo?.initialized && Date.now() - currDeviceInfo.initialized > 5000) ||
      (subDevice && currDeviceInfo?.updateTimestamp && Date.now() - currDeviceInfo.updateTimestamp > 10000)
    ) {
      getLiveStream();
    }
    else initialQueryTimeoutId.current = setTimeout(queryLiveStream, 10);
  },[dockInfo, getLiveStream]);

  const {videoStream: stream} = usePlainVideoStream(streamingConfig, (result, method) => {
    const { status } = result;
    console.log('🖐️ whep-result', status, method, serialNumber);
  }, () => {
    if(!lastGetInfo.current) {
      setStreamingConfig(null);
      queryLiveStream();
    }
  });

  const refresh = () => {
    setStreamingConfig(null);
    lastGetInfo.current = null;
    queryLiveStream();
  }

  useEffect(() =>{
    console.log('✨ dock video change', {dockId, serialNumber, dockConnection, subDevice, streamingConfig});

    if(!dockId || !serialNumber || !dockConnection || dockConnection?.status !== deviceConnectionStatus.CONNECTED) {
      setStreamingConfig(null);
      return;
    }

    if(streamingConfig)
      return;

    dockConnectionManager.unsubscribeGroupMessages(groupHandlerIds.current);
    groupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: dockId,
        name: [dockConnection?.group?.join?.commands_reply],
        handler: (message) => {
          if(
            message?.method === djiCloudCustomMethod.live.DOCK_VIDEO_RESPONSE ||
            message?.method === djiCloudCustomMethod.live.DRONE_VIDEO_RESPONSE
          ){
            if(message?.tid !== lastGetInfo.current?.tid) return;

            clearTimeout(noResponseTimeoutId.current);
            clearTimeout(invalidResultTimeoutId.current);
            clearTimeout(initialQueryTimeoutId.current);

            lastGetInfo.current = null;

            if(message.data?.result === -1) {
              console.log('📺❌ Livestream config result invalid, retrying in 5 seconds...', serialNumber, message);
              
              invalidResultTimeoutId.current = setTimeout(() => {
                if(lastGetInfo.current === null)
                  getLiveStream();
              }, 5000);
              return;
            }

            if(streamingConfigRef.current) return;

            console.log('📺✨ Livestream result', serialNumber);
            lastGetInfo.current = null;

            // if(stream) {
            //   console.log('📺💡 Livestream result ignored: stream already exists');
            //   return;
            // }

            streamingConfigRef.current = message.data;
            dispatch(setDockConnectionInfo({
              deviceId: dockId,
              data: {
                [subDevice ? 'subStreamConfig' : 'dockStreamConfig']: message.data,
              },
            }));

            const subDeviceInfo = subDevice && getDeviceInfo(serialNumber);
            let configDelay = 0;

            if(subDeviceInfo) {
              const updateTimestamp = subDeviceInfo.updateTimestamp;
              configDelay = updateTimestamp && (Date.now() - updateTimestamp) < 20000 ? 0 : 0;

              console.log('📺⏳ Livestream delay', { difference: Date.now() - updateTimestamp, configDelay });
            }

            setTimeout(() => setStreamingConfig(() => {
              const data = message.data;

              const newConfig = {
                ...data,
                gateway: data?.gateway || dockInfo?.serialNumber,
                timestamp: Date.now(),
              };

              console.log('📺☑️ Livestream result set', serialNumber, newConfig);

              return newConfig;
            }), configDelay);
          }

          // Detect stream publish
          if(
            message?.method === djiCloudCustomMethod.live.DOCK_VIDEO_PUBLISHED ||
            message?.method === djiCloudCustomMethod.live.DRONE_VIDEO_PUBLISHED
          ){
            if(message.data?.serialNumber !== serialNumber) return;
            console.log('📺▶️ Livestream published', serialNumber, message);
          }
        },
      },
    ], 'dock-live-stream');

    queryLiveStream();
  }, [dockId, serialNumber, subDevice, dockConnection]);

  useEffect(() => {
    if(streamStatus === 0)
      refresh();
  }, [streamStatus]);

  useEffect(() => {
    return () => {
      dockConnectionManager.unsubscribeGroupMessages(groupHandlerIds.current);
      clearTimeout(noResponseTimeoutId.current);
      clearTimeout(initialQueryTimeoutId.current);
      clearTimeout(invalidResultTimeoutId.current);
    }
  }, []);

  return streamingConfig ? {
    stream,
    videoId,
    refresh,
    dockId,
    config: streamingConfig,
  } : null;
}