import { Switch as RadixSwitch } from '@radix-ui/themes'
import { SwitchProps } from '@radix-ui/themes/dist/cjs/components/switch'
import classNames from 'classnames'

function Switch({ className, radius='full', ...props}: SwitchProps) {
  return (
    <RadixSwitch className={classNames('ir-switch', className)} radius={radius} {...props} />
  )
}

export default Switch