import classNames from 'classnames'
import './Form.sass'
import { forwardRef } from 'react';

export const FormDirection = {
  ROW: 'row',
  COLUMN: 'column'
}

const Form = forwardRef(({ className, children, direction, instance, ...props }, ref) => {
  let dir = direction;

  if (dir && !Object.values(FormDirection).includes(dir))
    dir = FormDirection.COLUMN;

  return (
    <form className={classNames("ir-form", dir, className)} ref={ref} onSubmit={e => {
      if(instance) {
        e.preventDefault();
        e.stopPropagation();
        instance.handleSubmit();
      }
    }} {...props}>
      {children}
    </form>
  )
});

export default Form