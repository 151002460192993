import './TextInput.sass'
import { TextField as RadixTextField } from '@radix-ui/themes'
import { TextFieldInputProps } from '@radix-ui/themes/dist/cjs/components/text-field'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { setDisableKeyboardEvents } from 'src/store/UiSlice'

function TextInput({ prefix, postfix, className, onBlur, onFocus, ...props}: TextFieldInputProps & { prefix?: React.ReactNode, postfix?: React.ReactNode }) {
  const dispatch = useDispatch();

  return (
    <RadixTextField.Root className={classNames('ir-text-input', className)} radius="medium" variant="soft" color="gray">
      {prefix ? <RadixTextField.Slot className='prefix'>{ prefix }</RadixTextField.Slot> : null}
      <RadixTextField.Input 
        className='input' 
        onFocus={(e) => {
          onFocus && onFocus(e);
          dispatch(setDisableKeyboardEvents(true));
        }}
        onBlur={(e) => {
          onBlur && onBlur(e);
          dispatch(setDisableKeyboardEvents(false));
        }}
        {...props} 
      />
      {postfix ? <RadixTextField.Slot className='postfix'>{ postfix }</RadixTextField.Slot> : null}
    </RadixTextField.Root>
  )
}

export default TextInput