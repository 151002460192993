import './Separator.sass'
import classNames from 'classnames'
import React from 'react'

export const SeparatorTitlePosition = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
} as const;

interface SeparatorProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string,
  titlePosition?: typeof SeparatorTitlePosition[keyof typeof SeparatorTitlePosition],
}

function Separator({ title, className, titlePosition = SeparatorTitlePosition.LEFT, ...props }: SeparatorProps) {
  return (
    <div className={classNames("ir-separator", {'with-title': !!title}, className, titlePosition)} {...props}>
      <div className="separator-line"></div>
      {title && <div className="separator-title">{title}</div>}
      <div className="separator-line"></div>
    </div>
  )
}

export default Separator