import { useDispatch, useStore } from 'react-redux';
import { dockConnectionManager } from 'src/helper/HubConnectionManager';
import { setDeviceInfo } from '../DeviceServiceSlice';
import { getDeviceConnectionGroups, getDeviceInfo, getDeviceTelemetries, getSubDeviceTelemetries } from '../common/deviceUtils';
import { useEffect, useRef } from 'react';
import { djiCloudMethod } from 'src/services/common/constants';
import { getMissionInfo } from 'src/services/mission/common/missionUtils';
import { safeToggleVideoRecord } from '../common/deviceCaptureUtils';
import { DjiCloudDrcState, DjiDockDroneMode, DjiDockMode } from '../common/deviceConstants';
import useCloudLiveFlight from 'src/services/mission/common/useCloudLiveFlight';
import useCurrentUserId from 'src/helper/useCurrentUserId';

function useDockDeviceObserver() {
  const messageHandlerIds = useRef([]);
  const store = useStore();
  const dispatch = useDispatch();
  const liveFlight = useCloudLiveFlight();
  const userId = useCurrentUserId();

  useEffect(() => {
    messageHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: 'dockDeviceState/*',
        name: ['state', 'events'],
        handler: (message, meta) => {
          const deviceId = meta?.fromUserId?.split('_')[0];
          const deviceInfo = getDeviceInfo(deviceId);
          const connection = getDeviceConnectionGroups(deviceId);
          const data = message.data;
          const method = message.method;
  
          if (data?.live_status) {
            const currStreamStatus = store.getState().deviceService.devices?.find(device => device.id === deviceId)?.streamStatus;

            data.live_status.forEach((liveStatus) => {
              const updateTarget = liveStatus.video_id.includes(deviceInfo?.serialNumber) ? 'dock' : 'subDevice';
              
              if(currStreamStatus?.[updateTarget] !== liveStatus.error_status) {
                dispatch(setDeviceInfo({
                  deviceId,
                  data: {
                    streamStatus: {
                      ...currStreamStatus,
                      [updateTarget]: liveStatus.error_status
                    }
                  }
                }));
              }
            });
          }

          if(method === djiCloudMethod.event.HMS) {
            if(data?.list?.find(hms => hms.code === '0x1C001307')) {
              const missionInfo = getMissionInfo(mission => mission.deviceId === deviceId);

              setTimeout(() => {
                const telemetries = getSubDeviceTelemetries(deviceId);
                console.log('🎥 Recording available', {missionInfo, telemetries, userId});

                // mission auto recording
                if(missionInfo?.missionDetails?.autoRecord && telemetries?.mode_code !== DjiDockDroneMode.STANDBY && missionInfo?.controllerId === userId) {
                  safeToggleVideoRecord(deviceId, true);
                }
              }, 5000);
            }
          }

          if(method === djiCloudMethod.event.TRACK) {
            console.log('📃 TRACK event', data);

            if(data?.list?.find(item => item.type === 'flighttask_prepare')) {
              const missionInfo = getMissionInfo(mission => mission.deviceId === deviceId);
              const telemetries = getDeviceTelemetries(deviceId);

              console.log('TRACK event inside', {missionInfo, telemetries, userId});

              // DRC auto enabling
              if(telemetries?.drc_state === DjiCloudDrcState.DISABLED && missionInfo?.controllerId === userId) {
                liveFlight.sendStartDrcChannel(deviceId);
              }
            }
          }

          // Reply to state messages with need_reply
          if(meta.groupName === 'state') {
            const fromSubDevice = meta.serialNumber !== deviceInfo?.serialNumber;

            if(message.need_reply) {
              const targetGroup = fromSubDevice ? connection?.subDevice?.send?.state_reply : connection?.send?.state_reply;

              dockConnectionManager.sendToDjiCloudGroup(deviceId, targetGroup, {
                data: {
                  result: 0,
                },
                bid: message.bid,
                tid: message.tid,
              });

              console.log('ℹ️ Acknowledged state message', {deviceId, targetGroup, connection, message});
            }

            // Set initial drone settings
            if(data?.firmware_version && fromSubDevice) {
              dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.subDevice?.send?.set, {
                data: {
                  camera_watermark_settings: {
                    global_enable: 1,
                    layout: 0,
                    datetime_enable: 1,
                    drone_sn_enable: 1,
                    gps_enable: 1,
                    drone_type_enable: 1,
                    user_custom_string_enable: 1,
                    user_custom_string: "Intuitive Robotics",
                  }
                },
              });

              console.log('⚙️ Set initial drone settings', {
                serialNumber: meta.serialNumber, 
                firmware: data.firmware_version}
              );
            }
          }
        },
      },
    ], 'dock-device-observer');

    return () => {
      dockConnectionManager.unsubscribeGroupMessages(messageHandlerIds.current);
    }
  }, []);

  return {};
}

export default useDockDeviceObserver