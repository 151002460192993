import './DeviceOptionsPanel.sass'
import CollapsablePanel from '../../../common/CollapsablePanel/CollapsablePanel';
import useDeviceInfo from 'src/services/device/common/useDeviceInfo';
import Switch from 'src/hci/common/Switch/Switch';
import { useEffect, useState, useRef } from 'react';
import useDeviceTelemetries from 'src/services/device/common/useDeviceTelemetries';
import { EntityType } from '../../../EntityList/EntityList';
import { getDeviceTelemetries, getSubDeviceTelemetries } from 'src/services/device/common/deviceUtils';

export default function DeviceOptionsPanel({ entity, onClose, collapsed, missionController }) {
  const deviceInfo = useDeviceInfo(entity?.id);
  const subDeviceOnlineStatus = useDeviceTelemetries(entity?.id, telemetry => telemetry?.sub_device?.device_online_status);
  const [uavSwitchActive, setUavSwitchActive] = useState(false);
  const [uavDataEnabled, setUavDataEnabled] = useState(false);
  const uavDataSendIntervalId = useRef();

  const handleClose = () => {
    onClose?.();
  }

  const sendUavData = (data) => {
    const endpoint = 'https://sandbox-public-api.safesky.app/v1/uav';
    const xApiKey = '18371ec0cb8146b4bcab4b1738eef46e';

    const normalizedData = [{
      ...data,
      altitude: Math.floor(data.altitude),
      course: Math.floor(data.course),
      ground_speed: Math.floor(data.ground_speed)
    }];

    fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': xApiKey
      },
      body: JSON.stringify(normalizedData)
    });
  
    console.log('ℹ️ UAV data', normalizedData);
  }

  useEffect(() => {
    if(subDeviceOnlineStatus || entity?.entityType === EntityType.DRONE) {
      setUavDataEnabled(!!uavSwitchActive);
    }
    else setUavDataEnabled(false);
  }, [entity, subDeviceOnlineStatus, uavSwitchActive]);

  useEffect(() => {
    if(uavDataEnabled && !uavDataSendIntervalId.current) {
      uavDataSendIntervalId.current = setInterval(() => {
        let data = {
          id: 'uav2234',
          call_sign: 'DJI-' + (deviceInfo?.serialNumber || entity.id)
        };

        if(subDeviceOnlineStatus) {
          const telemetries = getSubDeviceTelemetries(entity.id);

          data = {
            ...data,
            latitude: telemetries?.latitude,
            longitude: telemetries?.longitude,
            altitude: telemetries?.elevation || 0,
            course: telemetries?.attitude_head || 0,
            ground_speed: telemetries?.horizontal_speed || 0,
          };
        } else {
          const telemetries = getDeviceTelemetries(entity.id);
          const flightStatus = telemetries?.flightControl?.flightStatus;
          const speed = Math.sqrt(Math.pow(telemetries?.velocity?.x, 2) + Math.pow(telemetries?.velocity?.y, 2));
          let droneAltitude;

          if(flightStatus === 2 && telemetries?.gps)
            droneAltitude = telemetries?.altitudeInfo?.asl - telemetries?.gps?.homepoint?.alt;

          data = {
            ...data,
            latitude: telemetries?.gps?.lat,
            longitude: telemetries?.gps?.long,
            altitude: droneAltitude || 0,
            course: telemetries?.facing?.heading || 0,
            ground_speed: speed || 0,
          };
        }

        sendUavData(data);
      }, 1000);
    } else if(!uavDataEnabled) {
      clearInterval(uavDataSendIntervalId.current);
      uavDataSendIntervalId.current = null;
      
      console.log('ℹ️ UAV data sending stopped');
    }
  }, [uavDataEnabled]);

  return (
    <div className="device-options-panel">
      <CollapsablePanel title="Device Options" collapsed={collapsed}>
        <div className="options-items">
          <div className="item">
            <Switch onCheckedChange={checked => setUavSwitchActive(checked)} />
            Send UAV Data
          </div>
        </div>
      </CollapsablePanel>
    </div>
  )
}
