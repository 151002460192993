import { BiCross, BiCrosshair } from 'react-icons/bi';
import './Dialog.sass';
import * as RadixDialog from '@radix-ui/react-dialog';
import { forwardRef } from 'react';
import classNames from 'classnames';
import { CgClose } from 'react-icons/cg';

const Body = ({className, children}) => (
  <div className={classNames("dialog-body", className)}>
    {children}
  </div>
);

const Title = ({className, children}) => (
  <RadixDialog.Title className={classNames("dialog-title", className)}>
    {children}
  </RadixDialog.Title>
);

const Description = ({className, children}) => (
  <RadixDialog.Description className={classNames("dialog-description", className)}>
    {children}
  </RadixDialog.Description>
);

const Dialog = forwardRef(({title, description, trigger, children, className, open, onOpenChange, key}, ref) => {
  const rootElement = document.getElementsByClassName("radix-themes")[0];
 
  return (
    <RadixDialog.Root {...{open, onOpenChange, key}}>
      {!!trigger && (
        <RadixDialog.Trigger asChild>
          {trigger}
        </RadixDialog.Trigger>
      )}
      <RadixDialog.Portal container={rootElement}>
        <RadixDialog.Overlay className="dialog-overlay">
          <RadixDialog.Content className={classNames("dialog-content", className)} ref={ref} onPointerDownOutside={e => e.preventDefault()}>
            {!!title && (
              <div className="dialog-header">
                <Title>{title}</Title>
                {!!description && (
                  <Description>
                    {description}
                  </Description>
                )}
              </div>
            )}
            {children}
            <RadixDialog.Close className="dialog-close no-default-style" asChild>
              <button aria-label="Close">
                <CgClose />
              </button>
            </RadixDialog.Close>
          </RadixDialog.Content>
        </RadixDialog.Overlay>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  )
});

Dialog.Title = Title;
Dialog.Description = Description;
Dialog.Close = RadixDialog.Close;
Dialog.Body = Body;

export default Dialog;