import * as React from "react";
const SvgDestinationPoint = (props) => /* @__PURE__ */ React.createElement("svg", { width: "1em", height: "1em", viewBox: "0 0 384 512", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", "xmlns:serif": "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M384,192C384,279.4 267,435 215.7,499.2C209.974,506.443 201.233,510.676 192,510.676C182.767,510.676 174.026,506.443 168.3,499.2C116.1,435 0,279.4 0,192C0,86 86,0 192,0C298,0 384,86 384,192Z", style: {
  fill: "var(--icon-base-color, rgb(251,5,5))",
  fillRule: "nonzero"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 192, cy: 191, r: 152, style: {
  fill: "var(--icon-circle-color, rgb(170,0,0))"
} }), /* @__PURE__ */ React.createElement("g", { id: "Location--filled-", "serif:id": "Location (filled)" }));
export default SvgDestinationPoint;
