import { ScrollArea } from 'src/hci/common/ScrollArea/ScrollArea'
import './DeviceMediaList.sass'
import { MdFormatListBulleted, MdOutlineFileDownload, MdOutlineGridView, MdVideoFile } from 'react-icons/md'
import Loading from 'src/hci/common/Loading/Loading';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getS3BucketSessionCredentials, setS3BucketSessionCredentials } from 'src/services/device/DeviceServiceSlice';
import { S3Client, ListObjectsV2Command, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { FaFile, FaFileImage, FaFileVideo, FaFolder } from 'react-icons/fa';
import { saveAs } from 'file-saver';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import prettyBytes from 'pretty-bytes';
import { RiArrowGoBackFill, RiFoldersLine } from 'react-icons/ri';
import { TbClock } from 'react-icons/tb';
import classNames from 'classnames';
import EmptyMediaList from './EmptyMediaList/EmptyMediaList';
import DropdownMenu from 'src/hci/common/DropdownMenu/DropdownMenu';
import Badge from 'src/hci/common/Badge/Badge';
import { getDockMissions } from 'src/services/mission/MissionServiceSlice';

const DEVICE_MEDIA_LIST_STATUS = {
  GETTING_CREDENTIALS: 'Getting Credentials',
  GETTING_MEDIA_LIST: 'Getting Media List',
  GETTING_MISSION_LIST: 'Getting Mission List',
  CREDENTIAL_FAILED: 'Failed to get credentials',
  MEDIA_LIST_FAILED: 'Failed to get media list',
  MEDIA_LIST_RECEIVED: 'Media List Received',
}

const DEVICE_MEDIA_LIST_STYLE = {
  THUMBNAIL: 'thumbnail-list',
  DETAILED: 'detailed-list',
}

const DEVICE_MEDIA_LIST_GROUPING = {
  DATE: 'date',
  MISSION: 'mission',
}

const DEVICE_MEDIA_LIST_CONTENT_TAG = {
  LOW_SIZE: 'S',
  THERMAL: 'T',
  STANDARD: 'V',
}

const DEVICE_MEDIA_LIST_CONTENT_TAG_TITLE = {
  [DEVICE_MEDIA_LIST_CONTENT_TAG.LOW_SIZE]: 'Compressed',
  [DEVICE_MEDIA_LIST_CONTENT_TAG.THERMAL]: 'Thermal',
  [DEVICE_MEDIA_LIST_CONTENT_TAG.STANDARD]: 'Standard',
}

const DEVICE_MEDIA_LIST_CONTENT_TAG_COLOR = {
  [DEVICE_MEDIA_LIST_CONTENT_TAG.LOW_SIZE]: 'green',
  [DEVICE_MEDIA_LIST_CONTENT_TAG.THERMAL]: 'red',
  [DEVICE_MEDIA_LIST_CONTENT_TAG.STANDARD]: 'blue',
}

const DeviceMediaList = React.memo(({ bucketId, bucketName, bucketRegion, deviceId, onPanelHeaderLeftChange, onPanelHeaderToolsChange, path }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [mediaList, setMediaList] = useState([]);
  const [status, setStatus] = useState(DEVICE_MEDIA_LIST_STATUS.GETTING_CREDENTIALS);
  const [mediaListLoaded, setMediaListLoaded] = useState(false);
  const [currDir, setCurrDir] = useState(path);
  const [message, setMessage] = useState();
  const [groupBy, setGroupBy] = useState(DEVICE_MEDIA_LIST_GROUPING.MISSION);
  const [listStyle, setListStyle] = useState(DEVICE_MEDIA_LIST_STYLE.DETAILED);
  const dispatch = useDispatch();
  const s3CredentialsRequest = useSelector(state => state.deviceService.s3BucketSessionCredentialRequest);
  const s3Credentials = useSelector(state => state.deviceService.s3BucketSessionCredentials?.[bucketId]?.credential);
  const s3Client = useRef(null);
  const dockMissions = useSelector(state => state.missionService.dockMissions[deviceId]);

  const getMediaIconByFileName = (fileName) => {
    const ext = fileName.split('.').pop();

    if(!fileName?.includes('.')) return <FaFolder className="icon" />
    if(fileName === '..') return <RiArrowGoBackFill className="icon" />

    // videos
    if (['mp4', 'avi', 'mov', 'mkv'].includes(ext)) {
      return <FaFileVideo className="icon" />
    }
    
    // images
    if (['jpg', 'jpeg', 'png', 'gif'].includes(ext)) {
      return <FaFileImage className="icon" />
    }

    return <FaFile className="icon" />
  }

  const getFileName = (fileName) => {
    if(fileName === '..') return 'Return to parent folder';
    return fileName.split('/').pop() || fileName;
  }

  const getBucketCredentials = useCallback(() => {
    if(!s3Credentials || Date.now() - s3Credentials.requestTimestamp > 1800000) {
      console.log('📂 Getting S3 bucket session credentials');
      setIsLoading(true);
      dispatch(getS3BucketSessionCredentials(bucketId));

      return false;
    }

    return true;
  }, [bucketId, dispatch, s3Credentials]);

  const getFilesList = (dir = currDir) => {
    if(!s3Client.current || !getBucketCredentials()) return;
    
    setStatus(DEVICE_MEDIA_LIST_STATUS.GETTING_MEDIA_LIST);
    setIsLoading(true);
    
    dir = dir && !dir.endsWith('/') ? dir + '/' : dir;
    
    const command = new ListObjectsV2Command({
      Bucket: bucketName,
      Prefix: dir,
    });
    
    s3Client.current
      .send(command)
      .then((data) => {
        if(data.Contents) {
          const list = data.Contents.filter(item => item.Key !== dir);

          //sort by folder first and then by modification time
          // list.sort((a, b) => {
          //   if(a.Key.endsWith('/') && !b.Key.endsWith('/')) return -1;
          //   if(!a.Key.endsWith('/') && b.Key.endsWith('/')) return 1;
          //   if(a.LastModified > b.LastModified) return -1;
          //   if(a.LastModified < b.LastModified) return 1;
          //   return 0;
          // });

          // if(dir && dir !== path && dir !== path + '/') {
          //   list.unshift({ Key: '..' });
          // }

          const filesWithNameInfo = list.filter(item => !item.Key.endsWith('/'))
            .map(item => ({ ...item, nameInfo: getFileNameInfo(item.Key) }));

          setMediaList(filesWithNameInfo);
          setMediaListLoaded(true);
          setStatus(null);
        }
      })
      .catch((error) => {
        console.error('📂 Media list from s3 bucket error', error);
        setStatus(DEVICE_MEDIA_LIST_STATUS.MEDIA_LIST_FAILED);
      })
      .finally(() => {
        if(!dockMissions)
          setStatus(DEVICE_MEDIA_LIST_STATUS.GETTING_MISSION_LIST);
        else
          setIsLoading(false);
      });
  }

  const getObjectUrl = async (key, withDownalodHeader = false) => {
    if(!key || !s3Client.current) return;

    try {
      const command = new GetObjectCommand({
        Bucket: bucketName,
        Key: key,
        ...(withDownalodHeader ? { ResponseContentDisposition: `attachment; filename="${key.split('/').pop()}"` } : {}),
      });
  
      const url = await getSignedUrl(s3Client.current, command, { expiresIn: 3600 });
      return url;
    } catch (error) {
      console.error('📂 Error generating URL:', error);
      return null;
    }
  }

  const fetchMediaFile = async (key) => {
    console.log('📂 Download media file', key);
    if(!s3Client.current) return;

    try {
      const command = new GetObjectCommand({ Bucket: bucketName, Key: key });
      const response = await s3Client.current.send(command);
      
      const chunks = [];
      for await (const chunk of response.Body) {
        chunks.push(chunk);
      }
      const blob = new Blob(chunks);
      return blob;
    } catch (error) {
      console.error('📂 Error downloading file:', error);
    }
  }

  function downloadUrl(url, filename) {
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = filename;
  
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }

  const fetchAndSaveMediaFile = async (key) => {
    if(!key) return;

    setMessage('Starting file download...');
    setIsLoading(true);

    const blob = await fetchMediaFile(key);

    setMessage(null);
    setIsLoading(false);
    
    if(blob)
      saveAs(blob, key.split('/').pop());
  }

  const openFileInNewTab = async (key) => {
    const url = await getObjectUrl(key);
    if (url) {
      window.open(url, '_blank');
    } else {
      console.error('📂 Failed to generate URL for key:', key);
    }
  }

  const downloadMediaFileByUrl = (key) => {
    if(!key) return;

    setMessage('Starting file download...');
    setIsLoading(true);

    getObjectUrl(key, true).then(url => {
      downloadUrl(url, key.split('/').pop());
      setTimeout(() => {
        setMessage(null);
        setIsLoading(false);
      }, 1000);
    });
  }

  const getFileNameInfo = (fileName) => {
    const pathParts = fileName.split('/');
    const name = pathParts.pop();
    const path = pathParts.join('/');
    const nameParts = name.split('.');
    const extension = nameParts.pop();
    const nameInfoParts = nameParts[0].split('_');
    const timestamp = moment(nameInfoParts[1], 'YYYYMMDDHHmmss').unix();

    return {
      name,
      path,
      extension,
      timestamp,
      raw: fileName,
      dateString: nameInfoParts[1],
      number: nameInfoParts[2],
      contentTag: nameInfoParts[3],
    }
  }

  const groupFilesByDate = (files) => {
    files.sort((a, b) => b.nameInfo.timestamp - a.nameInfo.timestamp);

    const result = files.reduce((acc, item) => {
      const dateKey = moment(item.nameInfo.dateString.substring(0, 8), 'YYYYMMDD').format("MMM DD, YYYY");
    
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(item);
    
      return acc;
    }, {});

    return result;
  }

  const groupFilesByMission = (files) => {
    files.sort((a, b) => b.nameInfo.timestamp - a.nameInfo.timestamp);

    const result = files.reduce((acc, item) => {
      const flightId = item.nameInfo.path.split('/')[1];
      const mission = dockMissions?.missions?.find(mission => mission.flightDetails?.Id === flightId);
      const missionKey = mission?.id;
    
      if (!acc[missionKey]) {
        acc[missionKey] = [];
      }
      acc[missionKey].push(item);
    
      return acc;
    }, {});

    return result;
  }

  const changeDir = (dir) => {
    setCurrDir(dir);
    getFilesList(dir);
  }

  const getMissionDetails = missionId => {
    const mission = dockMissions?.missions?.find(mission => mission.id === missionId);
    const startTime = moment(mission?.startTime);
    const endTime = moment(mission?.endTime);

    return {
      ...mission,
      duration: mission?.endTime - mission?.startTime,
      durationString: moment.duration(endTime.diff(startTime)).humanize(),
      startTimeString: startTime.format('MMM DD, YYYY HH:mm:ss'),
      endTimeString: endTime.format('MMM DD, YYYY HH:mm:ss'),
    }
  }

  const handleMediaItemClick = (key) => {
    console.log('📂 Media item clicked', key);

    if(key?.endsWith('/')) {
      changeDir(key);
    }
    else if(key === '..') {
      const dir = currDir.split('/').slice(0, -2).join('/');
      changeDir(dir);
    }
    else {
      openFileInNewTab(key);
    }
  }

  const handleListStyleChange = () => {
    setListStyle(curr => curr === DEVICE_MEDIA_LIST_STYLE.DETAILED ? DEVICE_MEDIA_LIST_STYLE.THUMBNAIL : DEVICE_MEDIA_LIST_STYLE.DETAILED);
  }

  const handleGroupByChange = (value) => {
    setGroupBy(value);
  }

  useEffect(() => {
    if (!bucketId) return;
    getBucketCredentials();
    dispatch(getDockMissions(deviceId));
  }, [bucketId]);

  useEffect(() => {
    if (!s3CredentialsRequest) return;

    if(s3CredentialsRequest.status === 'success') {
      dispatch(setS3BucketSessionCredentials({ bucketId, data: {
        ...s3CredentialsRequest.data,
        requestTimestamp: Date.now(),
      }}));
    }
    else {
      setStatus(DEVICE_MEDIA_LIST_STATUS.CREDENTIAL_FAILED);
      setIsLoading(false);
    }
  }, [s3CredentialsRequest]);

  useEffect(() => {
    if (!s3Credentials) return;
    console.log('📂 S3 credentials received', s3Credentials);

    // Get media list
    const clientConfig = { 
      region: bucketRegion,
      credentials: {
        accessKeyId: s3Credentials.access_key_id,
        secretAccessKey: s3Credentials.access_key_secret,
        sessionToken: s3Credentials.security_token,
      }
    }

    s3Client.current = new S3Client(clientConfig);
    
    setTimeout(() => {
      getFilesList();
    }, 500);
  }, [s3Credentials]);

  useEffect(() => {
    if(onPanelHeaderLeftChange) {
      onPanelHeaderLeftChange(
        <>
          {currDir ? (
            <div style={{ borderRadius: '1em', background: 'var(--ir-color-surface-2)', padding: '0.1em 0.9em', fontSize: '0.9em' }}>{currDir}</div>
          ) : null}
        </>
      );
    }

    if(onPanelHeaderToolsChange) {
      onPanelHeaderToolsChange(
        <>
          <div style={{ display: 'inline-block' }}>
            <DropdownMenu size='1' trigger={'Group by ' + (groupBy === DEVICE_MEDIA_LIST_GROUPING.MISSION ? 'Mission' : 'Date')}>
              {Object.entries(DEVICE_MEDIA_LIST_GROUPING).map(([key, value]) => (
                <DropdownMenu.Item style={{ textTransform: 'capitalize' }} onClick={() => handleGroupByChange(value)} key={key}>
                  {value}
                </DropdownMenu.Item>
              ))}
            </DropdownMenu>
          </div>

          {listStyle === DEVICE_MEDIA_LIST_STYLE.THUMBNAIL ? 
            <MdFormatListBulleted onClick={handleListStyleChange} tilte="Detailed List" /> : 
            <MdOutlineGridView onClick={handleListStyleChange} title="Thumbnail List" />
          }
        </>
      );
    }
  }, [currDir, listStyle, groupBy]);

  useEffect(() => {
    if(dockMissions && mediaListLoaded) {
      setTimeout(() => setIsLoading(false), 1000);
    }
  }, [dockMissions, mediaListLoaded]);

  const MediaList = ({ files }) => {
    console.log('media-list', files);
    
    return (
      <div className={classNames("media-list", listStyle)}>
        {files.map((media, index) => (
          <div className="media-item" key={media.Key} onClick={() => handleMediaItemClick(media.Key)}>
            <div className="thumbnail">
              {getMediaIconByFileName(media.Key)}
            </div>
            <div className="info">
              <div className="name">{getFileName(media.Key)}</div>
              <div className="badges">
                <Badge color={DEVICE_MEDIA_LIST_CONTENT_TAG_COLOR[media.nameInfo.contentTag]}>
                  {DEVICE_MEDIA_LIST_CONTENT_TAG_TITLE[media.nameInfo.contentTag]} 
                  {media.nameInfo.extension.toLowerCase() === 'mp4' ? ' Video' : ' Image'}
                </Badge>
              </div>
              {media.Key !== '..' ? (
                <>
                  <div className="time">
                    <TbClock className="icon" />
                    {momentTimezone.tz(media.nameInfo.dateString, 'YYYYMMDDHHmmss', 'Asia/Shanghai').local().format('MMM DD, YYYY HH:mm:ss')}
                  </div>
                  <div className="size">
                    <RiFoldersLine className="icon" />
                    {media.Size ? prettyBytes(media.Size) : 'Folder'}
                  </div>
                </>
              ) : null}
            </div>
            {media.Key !== '..' ? (
              <div className="tools">
                <MdOutlineFileDownload title="Download File" onClick={(e) => {
                  e.stopPropagation();
                  downloadMediaFileByUrl(media.Key);
                }} />
              </div>
            ) : null}
          </div>
        ))}
      </div>
    );
  }

  const MediaListGroup = ({ files }) => {
    const groupedFiles = groupBy === DEVICE_MEDIA_LIST_GROUPING.DATE ? groupFilesByDate(files) : groupFilesByMission(files);

    return (
      <>
        {Object.entries(groupedFiles).map(([groupKey, groupFiles]) => {
          const mission = groupBy === DEVICE_MEDIA_LIST_GROUPING.MISSION ? getMissionDetails(groupKey) : null;

          return (
            <div className="media-list-group" key={groupKey}>
              <div className="group-header">
                <div className="group-title">{mission ? (mission.name || mission.flightDetails?.id) : groupKey}</div>
                <div className="group-details">
                  {mission && mission.startTime ? <>
                    <span>{mission.startTimeString} ({groupFiles?.length} media)</span>
                  </> : (
                    <span>Captured {groupFiles?.length} media</span>
                  )}
                </div>
              </div>
              <MediaList files={groupFiles} />
            </div>
          );
        })}
      </>
    );
  }

  return (
    <div className="device-media-list">
      <div className="container">
        {isLoading ? (
          <Loading withBackground={false} message={message || (status ? status + '...' : null)} />
        ) : mediaList?.length ? (
          <ScrollArea className="media-list-scroll">
            <MediaListGroup files={mediaList} />
          </ScrollArea>
        ) : (
          <EmptyMediaList />
        )}
      </div>
    </div>
  )
});

export default DeviceMediaList