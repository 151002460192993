import './DropdownMenu.sass'
import { DropdownMenu as RadixDropdown } from '@radix-ui/themes'
import { DropdownMenuContentProps, DropdownMenuRootProps } from '@radix-ui/themes/dist/cjs/components/dropdown-menu'
import Button from '../Button/Button';
import { TbChevronDown } from 'react-icons/tb';

interface DropdownMenuProps extends DropdownMenuRootProps {
  className?: string;
  style?: React.CSSProperties;
  trigger: React.ReactNode | string;
}

function DropdownMenu({ className, children, trigger, style, size = '2', ...props}: DropdownMenuProps & DropdownMenuContentProps) {
  return (
    <RadixDropdown.Root {...props}>
      <RadixDropdown.Trigger>
        {typeof trigger === 'string' ? (
          <Button variant='soft' color='gray' size={size}>
            {trigger}
            <TbChevronDown />
          </Button>
        ) : trigger}
      </RadixDropdown.Trigger>
      <RadixDropdown.Content>
        {children}
      </RadixDropdown.Content>
    </RadixDropdown.Root>
  )
}

DropdownMenu.Trigger = RadixDropdown.Trigger;
DropdownMenu.Content = RadixDropdown.Content;
DropdownMenu.Item = RadixDropdown.Item;
DropdownMenu.Separator = RadixDropdown.Separator;
DropdownMenu.Sub = RadixDropdown.Sub;
DropdownMenu.SubTrigger = RadixDropdown.SubTrigger;
DropdownMenu.SubContent = RadixDropdown.SubContent;
DropdownMenu.Root = RadixDropdown.Root;

export default DropdownMenu