import FormItem from 'src/hci/common/FormItem/FormItem'
import './ShareStreamDialog.sass'
import Form, { FormDirection } from 'src/hci/common/Form/Form'
import { useForm } from '@tanstack/react-form'
import Button from 'src/hci/common/Button/Button'
import TextInput from 'src/hci/common/TextInput/TextInput'
import { useEffect, useRef, useState } from 'react'
import { FiCopy } from 'react-icons/fi'
import { dockConnectionManager } from 'src/helper/HubConnectionManager'
import { getMissionInfo } from 'src/services/mission/common/missionUtils'
import useDeviceInfo from 'src/services/device/common/useDeviceInfo'
import useSubDevice from 'src/services/device/common/useSubDevice'
import useCurrentUserId from 'src/helper/useCurrentUserId'
import useDeviceConnection from 'src/services/device/common/useDeviceConnection'
import { djiCloudCustomMethod } from 'src/services/common/constants'
import { generateId } from 'src/helper/utils'
import Separator from 'src/hci/common/Separator/Separator'
import Select from 'src/hci/common/Select/Select'
import { FaLink } from 'react-icons/fa6'
import useDialog from 'src/helper/useDialog'

export const ShareStreamSeverity = {
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High',
}

function ShareStreamDialog({deviceId, subDevice = false}) {
  const [sharableUrl, setSharableUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dockConnection = useDeviceConnection(deviceId);
  const requestTid = useRef(null);
  const groupHandlerIds = useRef([]);
  const dockInfo = useDeviceInfo(deviceId);
  const subDeviceInfo = useSubDevice(deviceId);
  const currUserId = useCurrentUserId();
  const dialog = useDialog();

  const handleFormSubmit = ({value}) => {
    if(value.duration) {
      getSharableUrl(value.duration, value.emails, value.description, value.status);
    }
  }

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(sharableUrl);
  }

  const getSharableUrl = (duration, emails, description, status) => {
    const method = subDevice ? djiCloudCustomMethod.live.GET_DRONE_SHARED_VIDEO_LINK : djiCloudCustomMethod.live.GET_DOCK_SHARED_VIDEO_LINK;
    const missionInfo = getMissionInfo(mission => mission.deviceId === deviceId);
    const serialNumber = subDevice ? subDeviceInfo?.device_sn : dockInfo?.serialNumber;
    
    const emailArray = emails?.split(',') || [];
    let missionId = missionInfo?.id;

    if(!serialNumber) {
      console.log('🔗⚠️ Device serial number not found for share url', {missionInfo, serialNumber});
    }

    if(!missionId) {
      console.log('🔗⚠️ Mission id not found for share url, crating a dummy id...', {missionInfo, serialNumber});
      missionId = generateId();
    }

    setSharableUrl('');
    requestTid.current = null;
    setIsLoading(true);

    const { tid } = dockConnectionManager.sendToDjiCloudGroup(deviceId, dockConnection?.group?.send?.commands, {
      method: method,
      data: {
        serialNumber: serialNumber,
      },
      gateway: dockInfo?.serialNumber,
      userId: currUserId,
      expire: Number(duration),
      emails: emailArray,
      description: description || 'No description provided',
      status,
      missionId,
    });

    requestTid.current = tid;
    console.log('🔗 Get share url sent', serialNumber, duration);
  }

  const form = useForm({
    defaultValues: {
      duration: '60',
      status: ShareStreamSeverity.MEDIUM
    },
    onSubmit: handleFormSubmit
  });

  useEffect(() => {
    dockConnectionManager.unsubscribeGroupMessages(groupHandlerIds.current);
    groupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: deviceId,
        name: [dockConnection?.group?.join?.commands_reply],
        handler: (message) => {
          if(
            message?.method === djiCloudCustomMethod.live.GET_DOCK_SHARED_VIDEO_LINK_RESPONSE ||
            message?.method === djiCloudCustomMethod.live.GET_DRONE_SHARED_VIDEO_LINK_RESPONSE
          ){
            if(message?.tid === requestTid.current){
              requestTid.current = null;
              setIsLoading(false);
              setSharableUrl(message?.data?.url);

              if(Array.isArray(message.emails) && message.emails.length) {
                dialog.fire({
                  title: <b>Sharing Stream Link</b>,
                  text: "The stream link has been sent to the email addresses you provided.",
                  icon: 'success',
                });
              }

              console.log('🔗 Share url received', message?.data?.url);
            }
          }
        }
      }
    ]);
  }, [deviceId]);

  return (
    <div className="share-stream-dialog">
      <div className="description">You can create a sharable web address for this stream with specific duration and send it to other people.</div>
      <div className="share-form">
        <Form direction={FormDirection.ROW} instance={form}>
          <FormItem label="Duration" className="duration">
            <form.Field 
              name='duration'
              children={field => (
                <TextInput
                  name={field.name}
                  postfix='minutes' 
                  value={field.state.value} 
                  onChange={e => field.handleChange(e.target.value)}
                  onBlur={field.handleBlur} 
                />
              )}
            />
          </FormItem>
          <FormItem label="Sharable Address" style={{ flexGrow: '1' }}>
            <TextInput
              name="url"
              value={sharableUrl} 
              placeholder='Press Create Link...'
              disabled={!sharableUrl}
              postfix={<FiCopy title='Copy share stream address' className='copy-button' onClick={handleCopyUrl} />}
            />
          </FormItem>
          <Separator title="Share Link by Email" titlePosition='center' />
          <FormItem label="Email Address" style={{ flex: '1 0 70%' }}>
            <form.Field 
              name='emails'
              children={field => (
                <TextInput
                  name={field.name}
                  value={field.state.value}
                  onChange={e => field.handleChange(e.target.value)}
                  onBlur={field.handleBlur}
                  placeholder='Enter email address...'
                />
              )}
            />
          </FormItem>
          <FormItem label="Severity">
            <form.Field 
              name='status'
              children={field => (
                <Select
                  name={field.name} 
                  value={field.state.value} 
                  onValueChange={value => field.handleChange(value)}
                  content={[{
                    items: Object.entries(ShareStreamSeverity)?.map(([key, value]) => ({ value: value, label: value }))
                  }]} 
                />
              )}
            />
          </FormItem>
          <FormItem label="Description" style={{ flex: '1 0 100%' }}>
            <form.Field 
              name='description'
              children={field => (
                <TextInput
                  name={field.name}
                  value={field.state.value}
                  onChange={e => field.handleChange(e.target.value)}
                  onBlur={field.handleBlur}
                  placeholder='Enter description...'
                />
              )}
            />
          </FormItem>
          <form.Subscribe
            selector={(state) => [state.canSubmit]}
            children={([canSubmit]) => (
              <Button 
                type="submit" 
                disabled={!canSubmit} 
                loading={isLoading} 
                size="2"
                style={{ width: '100%' }}
                prefix={<FaLink className="icon" />}
              >Create & Share Link</Button>
            )}
          />
        </Form>
      </div>
    </div>
  )
}

export default ShareStreamDialog