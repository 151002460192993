import classNames from 'classnames'
import './FormItem.sass'
import React from 'react'

function FormItem({ children, label, className, error = false, ...props }) {
  return (
    <div className={classNames("ir-form-item", className, {'has-error': !!error})} {...props}>
      <label className="label">{ label }</label>
      <div className="field">{ !error ? children : React.cloneElement(children, { className: `${children.props.className} error`}) }</div>
      { error && <div className="error-message">{ error }</div> }
    </div>
  )
}

export default FormItem