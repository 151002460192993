import './DeviceMapToolbar.sass';
import classNames from 'classnames';

export default function DeviceMapToolbar({ extended, buttons }) {
  return (
    <div className={classNames("device-map-toolbar", { extended })}>
      {buttons?.map((button, index) => (
        <button
          key={index}
          className={classNames("toolbar-item", { active: button.active, toggle: button.toggle })}
          title={button.title}
          onClick={button.onClick}
        >
          {button.icon}
        </button>
      ))}
    </div>
  )
}