import { useDispatch, useSelector } from 'react-redux';
import styles from './EntityList.module.sass';
import { useEffect, useRef, useState } from 'react';
import CollapsablePanel from '../common/CollapsablePanel/CollapsablePanel';
import { deviceTypes } from 'src/helper/constants';
import { setSelectedEntity } from '../ExploreAppSlice';
import { DeviceMapMarkerTypes } from 'src/components/DeviceMap/DeviceMap';
import EntityListItem from './EntityListItem/EntityListItem';
import useMobileClients from 'src/services/user/common/useMobileClients';
import { TbPlus } from 'react-icons/tb';
import AddDockDialog from '../../DeviceApp/DockManagerApp/AddDockDialog/AddDockDialog';
import Dialog from 'src/hci/common/Dialog/Dialog';
import useUnitList from '../../SecurityApp/common/useUnitList';
import { getDeviceInfo } from 'src/services/device/common/deviceUtils';

export const EntityType = {
  DOCK: 'dock',
  DRONE: 'drone',
  MOBILE_DEVICE: 'mobile_device',
  OPERATION_UNIT: 'operation_unit'
};

const EntityTypeToDeviceMapMarker = {
  [EntityType.DOCK]: DeviceMapMarkerTypes.DOCK,
  [EntityType.DRONE]: DeviceMapMarkerTypes.DRONE,
  [EntityType.MOBILE_DEVICE]: DeviceMapMarkerTypes.MOBILE_DEVICE,
  [EntityType.OPERATION_UNIT]: DeviceMapMarkerTypes.OPERATION_UNIT
};

function EntityList({ onAddDroneClick, onAddUnitClick, onEntityClick }) {
  const dispatch = useDispatch();
  const allDevices = useSelector((state) => state.deviceService.devices);
  const {data: operationUnits, refresh: refreshUnitList} = useUnitList();
  const selectedMapMarker = useSelector((state) => state.deviceMap.lastSelectedMarker);
  const mobileClients = useMobileClients();

  useEffect(() => {
    if (
      !selectedMapMarker || 
      !Object.values(EntityTypeToDeviceMapMarker).includes(selectedMapMarker.type) ||
      getDeviceInfo(selectedMapMarker.data?.sourceId)?.details?.parentId
    ) return;

    dispatch(setSelectedEntity({
      id: selectedMapMarker.data?.sourceId,
      ...selectedMapMarker.data?.raw,
      entityType: EntityTypeToDeviceMapMarker[selectedMapMarker.type] //TODO: Fix - entity type should be inverse!
    }));
  }, [selectedMapMarker]);

  const handleOnEntityClick = (data) => {
    onEntityClick?.(data);
  }

  const dockTools =<Dialog 
    title="Add Dock"
    trigger={(
      <div><TbPlus /></div>
    )}
  >
    <AddDockDialog />
  </Dialog>;

  const droneTools = [
    <TbPlus title="Add Drone" onClick={onAddDroneClick} key="add" />
  ];

  const unitTools = [
    <TbPlus title="Add Unit" onClick={onAddUnitClick} key="add" />
  ];

  return (
    <div className={styles.entityList}>
      <CollapsablePanel title="Dock Stations" className={styles.resourceGroup} noStyle tools={dockTools}>
        {allDevices?.filter(item => item.type === deviceTypes.DOCK)?.map(item => (
          <EntityListItem entity={item} type={EntityType.DOCK} key={item.id} onClick={handleOnEntityClick} />
        ))}
      </CollapsablePanel>
      <CollapsablePanel title="Drones" className={styles.resourceGroup} noStyle tools={droneTools}>
        {allDevices?.filter(item => item.type === deviceTypes.DRONE)?.map(item => (
          <EntityListItem entity={item} type={EntityType.DRONE} key={item.id} onClick={handleOnEntityClick} />
        ))}
      </CollapsablePanel>
      <CollapsablePanel title="Mobile Devices" className={styles.resourceGroup} noStyle>
        {mobileClients?.map(item => (
          <EntityListItem entity={item} type={EntityType.MOBILE_DEVICE} key={item.id} onClick={handleOnEntityClick} />
        ))}
      </CollapsablePanel>
      <CollapsablePanel title="Operation Units" className={styles.resourceGroup} noStyle tools={unitTools}>
        {operationUnits?.map(item => (
          <EntityListItem entity={item} type={EntityType.OPERATION_UNIT} key={item.id} onClick={handleOnEntityClick} />
        ))}
      </CollapsablePanel>
    </div>
  )
}

export default EntityList